import { Button, Col, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import Category from "../../components/Category/Category";
import Industry from "../../components/Industry/Industry";
import { useRouter } from "next/router";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./NewHomebanner.css";
import Head from "next/head";

const bannerSlides = [
  {
    id: "Slider1",
    image:
      "https://res.cloudinary.com/develperspot/image/upload/v1743059933/HBanner-01_nvhqqi.webp",
    title: "Client Asset Management",
    loading: "eager",
    priority: true,
    desc: "We will buy, sell, trade, manage, warehouse, & recycle your surplus business and industrial assets. These assets can be anything of value, tangible or non-tangible, pertaining to any business including inventory, fixed assets, intellectual property, web sites or the business itself.",
  },
  {
    id: "Slider2",
    image:
      "https://res.cloudinary.com/develperspot/image/upload/v1743059933/SpecializedContracts_rjx4rl.webp",
    title: "Specialized Contracts",
    loading: "lazy",
    priority: false,
    desc: "We pride ourselves on being one of the most agile teams in the Industry. We can build and manage the process itself, the software, or even provide the staffing. Designed based on your needs as a company, irrelevant of Industry. We are professionals in process design and business management.",
  },
  {
    id: "Slider3",
    image:
      "https://res.cloudinary.com/develperspot/image/upload/v1743059933/HBanner-02_np4db2.webp",
    title: "Surplus Inventory",
    loading: "lazy",
    priority: false,
    desc: "We have surplus inventory available for almost every business need, at any scale, and if we don't have it, We can get it! Who we are, our experience, our relationships and systems are completely custom and built to scale. You can count on us to provide quality surplus, facilitating cost savings to your bottom line, or we can locate those hard to find items you need!",
  },
  {
    id: "Slider4",
    image:
      "https://res.cloudinary.com/develperspot/image/upload/v1743059934/HBanner-03_q1u0mk.webp",
    title: "What Is Surplus?",
    loading: "lazy",
    priority: false,
    desc: "Surplus inventory is business equipment (an asset) you have, that is in excess or no longer needed or wanted for the foreseeable future.",
  },
  // {
  //   id: "Slider5",
  //   image:
  //     "https://res.cloudinary.com/develperspot/image/upload/v1743059933/SpecializedContracts_rjx4rl.webp",
  //   title: "Recycling Services",
  //   loading: "eager",
  //   priority: true,
  //   desc: "Have unique scrap recycling needs? We can manage all of it. From superalloys, titanium alloys, down to iron and cardboard. We can manage the full lifecycle of your scrap from shipment at your facilities to processing, handling, quality control and compliance.",
  // },
  {
    id: "Slider5",
    image:
      "https://res.cloudinary.com/develperspot/image/upload/v1743605563/new_slider_1_1_zzqrzm.webp",
    title: "Recycling Services",
    loading: "eager",
    priority: true,
    desc: "Have unique scrap recycling needs? We can manage all of it. From superalloys, titanium alloys, down to iron and cardboard. We can manage the full lifecycle of your scrap from shipment at your facilities to processing, handling, quality control and compliance.",
  },
];

function NewHomeBanner(industryData, categoryData) {
  const router = useRouter();
  const [isClient, setIsClient] = useState(false);
  useEffect(() => {
    setIsClient(true);
  }, []);
  var payload = {};
  const [shuffledSlides, setShuffledSlides] = useState([]);

  const handleChangeIndustry = (id) => {
    const parsedId = Number(id);
    if (parsedId && parsedId > 0) {
      payload.IndustryId = parsedId;
      payload.IsGlobelSearch = true;
    }
  };

  const handleChangeCategory = (id) => {
    const parsedId = Number(id);
    if (parsedId && parsedId > 0) {
      payload.CategoryId = parsedId;
      payload.IsGlobelSearch = true;
    }
  };

  useEffect(() => {
    // preloadImages();

    // Simply use the original bannerSlides array without shuffling
    setShuffledSlides([...bannerSlides]);

    // If you want to ensure Slider1 is always first, you could do this:
    // const firstSlide = bannerSlides.find(slide => slide.id === "Slider1");
    // const otherSlides = bannerSlides.filter(slide => slide.id !== "Slider1");
    // setShuffledSlides([firstSlide, ...otherSlides]);
  }, []);

  // useEffect(() => {

  //   // Get the permanent slide (first one)
  //   const permanentSlide = bannerSlides[0];

  //   // Get all other slides
  //   const slidesToShuffle = bannerSlides.slice(1);

  //   // Shuffle these slides
  //   const shuffled = slidesToShuffle.sort(() => Math.random() - 0.5);

  //   // Combine permanent slide with shuffled slides
  //   setShuffledSlides([permanentSlide, ...shuffled]);
  // }, []);

  useEffect(() => {
    // preloadImages();

    // Sort slides by their ID to ensure proper sequence
    const sortedSlides = [...bannerSlides].sort((a, b) => {
      // Extract the number from "SliderX"
      const numA = parseInt(a.id.replace("Slider", ""));
      const numB = parseInt(b.id.replace("Slider", ""));
      return numA - numB;
    });

    setShuffledSlides(sortedSlides);
  }, []);

  const settings = {
    infinite: true,
    dots: false,
    autoplay: true,
    navs: true,
    speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 10000,
    swipe: false,
    swipeToSlide: false,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          navs: false,
          dots: true,
        },
      },
    ],
  };

  const handleSearchClickEvent = () => {
    router.push("/Inventory");
  };

  return (
    <>
      <Head>
        <link
          rel="preload"
          as="image"
          href="https://res.cloudinary.com/develperspot/image/upload/v1743059933/SpecializedContracts_rjx4rl.webp"
          fetchPriority="high"
          importance="high"
        />
        <link
          rel="preload"
          as="image"
          href={bannerSlides[0].image}
          fetchPriority="high"
          importance="high"
        />
      </Head>
      <Slider {...settings} className="HomeBannerSlider">
        {shuffledSlides.map((slid, index) => (
          <div className="Home_Banner_Wrap" key={index}>
            <div className="container">
              <div className="Home_Banner_Info">
                <h1>{slid.title}</h1>
                <p>{slid.desc}</p>
                <div className="HomeIndustries">
                  <Row>
                    <Col lg={5} md={6} sm={12} className="DropZindex">
                      <Industry
                        onChangeIndustry={handleChangeIndustry}
                        industryData={industryData.industryData}
                      />
                    </Col>
                    <Col lg={5} md={6} sm={12}>
                      <Category
                        onChangeCategory={handleChangeCategory}
                        categoryData={industryData.industryData}
                      />
                    </Col>
                    <Col lg={2}>
                      <Button
                        variant=""
                        onClick={handleSearchClickEvent}
                        className="btn-blue btn-homeSearch home-search"
                      >
                        Search
                      </Button>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
            <div className="Home_Banner_Img">
              <div className="Home_Banner_item_img">
                <img
                  src={slid.image}
                  alt={slid.id}
                  loading={slid.loading} // Dynamically set loading
                  priority={slid.priority} // Dynamically set priority
                />
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </>
  );
}

export default NewHomeBanner;

// import { Button, Col, Row } from "react-bootstrap";
// import React, { useEffect, useState } from "react";
// import Category from "../../components/Category/Category";
// import Industry from "../../components/Industry/Industry";
// import { useRouter } from "next/router";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import "./NewHomebanner.css";
// import Image from "next/image";
// import Head from "next/head";

// const bannerSlides = [
//   {
//     id: "Slider1",
//     image:
//       "https://res.cloudinary.com/develperspot/image/upload/f_auto,q_auto:good,w_1920,h_600,c_fill/v1743059933/HBanner-01_nvhqqi.webp",
//     title: "Client Asset Management",
//     desc: "We will buy, sell, trade, manage, warehouse, & recycle your surplus business and industrial assets. These assets can be anything of value, tangible or non-tangible, pertaining to any business including inventory, fixed assets, intellectual property, web sites or the business itself.",
//   },
//   {
//     id: "Slider2",
//     image:
//       "https://res.cloudinary.com/develperspot/image/upload/f_auto,q_auto:good,w_1920,h_600,c_fill/v1743059933/HBanner-04_r2p8cl.webp",
//     title: "Specialized Contracts",
//     desc: "We pride ourselves on being one of the most agile teams in the Industry. We can build and manage the process itself, the software, or even provide the staffing. Designed based on your needs as a company, irrelevant of Industry. We are professionals in process design and business management.",
//   },
//   {
//     id: "Slider3",
//     image:
//       "https://res.cloudinary.com/develperspot/image/upload/f_auto,q_auto:good,w_1920,h_600,c_fill/v1743059933/HBanner-02_np4db2.webp",
//     title: "Surplus Inventory",
//     desc: "We have surplus inventory available for almost every business need, at any scale, and if we don't have it, We can get it! Who we are, our experience, our relationships and systems are completely custom and built to scale. You can count on us to provide quality surplus, facilitating cost savings to your bottom line, or we can locate those hard to find items you need!",
//   },
//   {
//     id: "Slider4",
//     image:
//       "https://res.cloudinary.com/develperspot/image/upload/f_auto,q_auto:good,w_1920,h_600,c_fill/v1743059934/HBanner-03_q1u0mk.webp",
//     title: "What Is Surplus?",
//     desc: "Surplus inventory is business equipment (an asset) you have, that is in excess or no longer needed or wanted for the foreseeable future.",
//   },
//   {
//     id: "Slider5",
//     image:
//       "https://res.cloudinary.com/develperspot/image/upload/f_auto,q_auto:good,w_1920,h_600,c_fill/v1743059933/SpecializedContracts_rjx4rl.webp",
//     title: "Recycling Services",
//     desc: "Have unique scrap recycling needs? We can manage all of it. From superalloys, titanium alloys, down to iron and cardboard. We can manage the full lifecycle of your scrap from shipment at your facilities to processing, handling, quality control and compliance.",
//   },
// ];

// function NewHomeBanner(industryData, categoryData) {
//   const router = useRouter();
//   const [isClient, setIsClient] = useState(false);
//   const [autoplay, setAutoplay] = useState(false);
//   const [visibleSlides, setVisibleSlides] = useState(1);
//   const [shuffledSlides, setShuffledSlides] = useState([]);
//   var payload = {};

//   useEffect(() => {
//     setIsClient(true);
//   }, []);

//   const handleChangeIndustry = (id) => {
//     const parsedId = Number(id);
//     if (parsedId && parsedId > 0) {
//       payload.IndustryId = parsedId;
//       payload.IsGlobelSearch = true;
//     }
//   };

//   const handleChangeCategory = (id) => {
//     const parsedId = Number(id);
//     if (parsedId && parsedId > 0) {
//       payload.CategoryId = parsedId;
//       payload.IsGlobelSearch = true;
//     }
//   };

//   // Preload only the first image
//   const preloadFirstImage = () => {
//     const firstSlide = bannerSlides[0];
//     const link = document.createElement("link");
//     link.rel = "preload";
//     link.as = "image";
//     link.href = firstSlide.image;
//     link.referrerPolicy = "no-referrer";
//     document.head.appendChild(link);
//   };

//   useEffect(() => {
//     preloadFirstImage();

//     // Get the permanent slide (first one)
//     const permanentSlide = bannerSlides[0];

//     // Get all other slides
//     const slidesToShuffle = bannerSlides.slice(1);

//     // Shuffle these slides
//     const shuffled = slidesToShuffle.sort(() => Math.random() - 0.5);

//     // Combine permanent slide with shuffled slides
//     setShuffledSlides([permanentSlide, ...shuffled]);

//     // Enable autoplay after first image is loaded
//     const timer = setTimeout(() => {
//       setAutoplay(true);
//       setVisibleSlides(bannerSlides.length);
//     }, 1000);

//     return () => clearTimeout(timer);
//   }, []);

//   const settings = {
//     infinite: true,
//     dots: false,
//     autoplay: autoplay,
//     navs: true,
//     speed: 400,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     autoplaySpeed: 7000,
//     lazyLoad: "ondemand",
//     swipe: false,
//     swipeToSlide: false,
//     responsive: [
//       {
//         breakpoint: 767,
//         settings: {
//           navs: false,
//           dots: true,
//         },
//       },
//     ],
//   };

//   const handleSearchClickEvent = () => {
//     router.push("/Inventory");
//   };

//   return (
//     <>
//       <Head>
//         <link
//           rel="preload"
//           as="image"
//           href={bannerSlides[0].image}
//           importance="high"
//         />
//       </Head>
//       <Slider {...settings} className="HomeBannerSlider">
//         {shuffledSlides.slice(0, visibleSlides).map((slid, index) => (
//           <div className="Home_Banner_Wrap" key={index}>
//             <div className="container">
//               <div className="Home_Banner_Info">
//                 <h1>{slid.title}</h1>
//                 <p>{slid.desc}</p>
//                 <div className="HomeIndustries">
//                   <Row>
//                     <Col lg={5} md={6} sm={12} className="DropZindex">
//                       <Industry
//                         onChangeIndustry={handleChangeIndustry}
//                         industryData={industryData.industryData}
//                       />
//                     </Col>
//                     <Col lg={5} md={6} sm={12}>
//                       <Category
//                         onChangeCategory={handleChangeCategory}
//                         categoryData={industryData.industryData}
//                       />
//                     </Col>
//                     <Col lg={2}>
//                       <Button
//                         variant=""
//                         onClick={handleSearchClickEvent}
//                         className="btn-blue btn-homeSearch home-search"
//                       >
//                         Search
//                       </Button>
//                     </Col>
//                   </Row>
//                 </div>
//               </div>
//             </div>
//             <div className="Home_Banner_Img">
//               <div className="Home_Banner_item_img">
//                 {index === 0 ? (
//                   <Image
//                     src={slid.image}
//                     alt={slid.id}
//                     height={600}
//                     width={1920}
//                     priority={true}
//                     loading="eager"
//                     quality={75}
//                     placeholder="blur"
//                     blurDataURL="data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wBDABQODxIPDRQSEBIXFRQYHjIhHhwcHj0sLiQySUBMS0dARkVQWnNiUFVtVkVGZIhlbXd7gYKBTmCNl4x9lnN+gXz/2wBDARUXFx4aHjshITt8U0ZTfHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHz/wAARCAAIAAoDASIAAhEBAxEB/8QAFQABAQAAAAAAAAAAAAAAAAAAAAb/xAAhEAACAQMDBQAAAAAAAAAAAAABAgMABAUGIWEREiMxUf/EABUBAQEAAAAAAAAAAAAAAAAAAAMF/8QAGhEAAgIDAAAAAAAAAAAAAAAAAAECEgMRkf/aAAwDAQACEQMRAD8AltJagyeH0AthI5xdrLcNM91BF5pX2HaH9bcfaSXWGaRmknyJckliyjqTzSlT54b6bk+h0R//2Q=="
//                   />
//                 ) : (
//                   <Image
//                     src={slid.image}
//                     alt={slid.id}
//                     height={600}
//                     width={1920}
//                     loading="lazy"
//                     quality={75}
//                   />
//                 )}
//               </div>
//             </div>
//           </div>
//         ))}
//       </Slider>
//     </>
//   );
// }

// export default NewHomeBanner;
